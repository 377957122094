<template>
  <div id="actionDialog">
    <el-dialog
      title="车辆操作"
      :visible.sync="show"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @closed="reset"
      @open="open"
      width="500px"
    >
      <el-form
        :model="actionForm"
        :rules="ruleForm"
        ref="actionForm"
        label-width="80px"
      >
        <el-form-item label="车牌号" prop="plateNum">
          <el-input
            v-model="actionForm.plateNum"
            placeholder="请输入车牌号"
          ></el-input>
        </el-form-item>
        <el-form-item label="车辆归属">
          <el-input
            v-model="actionForm.subjectName"
            placeholder="请输入车辆归属"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人">
          <el-input
            v-model="actionForm.contacts"
            placeholder="请输入联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系方式">
          <el-input
            v-model="actionForm.contactPhone"
            placeholder="请输入联系方式"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="actionForm.remarks"></el-input>
        </el-form-item>
        <el-form-item label="套餐" prop="priceId">
          <el-select v-model="actionForm.priceId" placeholder="请选择套餐">
            <el-option
              v-for="item of priceList"
              :key="item.priceId"
              :label="item.priceName"
              :value="item.priceId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="始终入场">
          <el-radio-group v-model="actionForm.alwaysOpen">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="租赁类型">
          <el-radio-group v-model="type">
            <el-radio label="无"></el-radio>
            <el-radio label="免费"></el-radio>
            <el-radio label="收费" disabled></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="时间范围" v-if="type !== '无'">
          <div
            class="leaseTime"
            v-for="(leaseTime, leaseTimeIndex) of leaseTimes"
            :key="leaseTimeIndex"
            :style="{
              marginBottom:
                leaseTimeIndex === leaseTimes.length - 1 ? '0' : '10px',
            }"
          >
            <el-date-picker
              v-model="leaseTimes[leaseTimeIndex]"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              @change="
                (value) => {
                  leaseTimesChange(value, leaseTimeIndex);
                }
              "
            >
            </el-date-picker>
            <span
              class="remove-leaseTime"
              @click="removeLeaseTime(leaseTimeIndex)"
              >移除</span
            >
          </div>
          <el-button
            type="primary"
            plain
            class="createTime"
            @click="createLeaseTime"
            >新增时间</el-button
          >
        </el-form-item>
        <el-form-item label="类型" v-if="type === '收费'" prop="leaseType">
          <el-select v-model="actionForm.leaseType" placeholder="请选择类型">
            <el-option label="按天" :value="0"></el-option>
            <el-option label="按月" :value="1"></el-option>
            <el-option label="按年" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分组" v-if="type === '免费'">
          <el-select
            v-model="actionForm.seatGroupId"
            placeholder="请选择分组"
            filterable
            clearable
            @change="seatGroupChange"
          >
            <el-option
              v-for="item of seatGroupList"
              :key="item.seatGroupId"
              :label="item.seatGroupName"
              :value="item.seatGroupId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="白名单">
          <ul class="black-white-list">
            <li v-for="item of whiteList" :key="item.channelId">
              <el-checkbox v-model="item.select">{{
                item.channelName
              }}</el-checkbox>
              <el-date-picker
                v-if="item.select"
                v-model="item.expirationDate"
                type="datetime"
                placeholder="到期时间"
                :clearable="false"
              >
              </el-date-picker>
            </li>
          </ul>
        </el-form-item>
        <el-form-item label="黑名单">
          <ul class="black-white-list">
            <li v-for="item of blackList" :key="item.channelId">
              <el-checkbox v-model="item.select">{{
                item.channelName
              }}</el-checkbox>
              <el-date-picker
                v-if="item.select"
                v-model="item.expirationDate"
                type="datetime"
                placeholder="到期时间"
                :clearable="false"
              >
              </el-date-picker>
            </li>
          </ul>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      loading: false,
      leaseId: "",
      type: "无",
      seatGroupList: [],
      priceList: [],
      parkingLotId: "",
      seatGroupId_backup: "",
      default_whiteList: [],
      default_blackList: [],
      whiteList: [],
      blackList: [],
      leaseTimes: [],
      actionForm: {
        plateNum: "",
        subjectName: "",
        contacts: "",
        contactPhone: "",
        remarks: "",
        alwaysOpen: 0,
        priceId: "",
        leaseType: "",
        seatGroupId: "",
      },
      ruleForm: {
        plateNum: [
          { required: true, message: "请输入车牌号", trigger: "blur" },
        ],
        priceId: [{ required: true, message: "请选择套餐", trigger: "change" }],
        leaseType: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    leaseTimesChange(value, leaseTimeIndex) {
      this.leaseTimes[leaseTimeIndex] = [
        value[0],
        this.$moment(value[1]).format("YYYY-MM-DDT23:59:59.SSSZZ"),
      ];
      this.$forceUpdate();
    },
    seatGroupChange(value) {
      if (!this.actionForm.seatGroupId) {
        this.$confirm('此操作将会把租赁类型切换为"无", 是否继续?', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.type = "无";
          })
          .catch(() => {
            this.actionForm.seatGroupId = this.seatGroupId_backup;
          });
      } else {
        this.seatGroupId_backup = value;
      }
    },
    open() {
      this.parkingLotId = this.$route.params.id;
      this.seatGroupId_backup = this.actionForm.seatGroupId;
      this.getSeatGroupList();
      this.getPriceList();
      this.getChannelList();
    },
    async getChannelList() {
      let res = await this.$http.post(`/channel/list/all`, {
        parkingLotId: this.parkingLotId,
      });
      const whiteList = [];
      const blackList = [];
      for (let item of res.data) {
        const white_item = this.default_whiteList.find(
          (items) => item.channelId === items.channelId
        );
        const black_item = this.default_blackList.find(
          (items) => item.channelId === items.channelId
        );
        whiteList.push({
          channelId: item.channelId,
          channelName: item.channelName,
          select: !!white_item,
          expirationDate: white_item ? white_item.expirationDate : "",
        });
        blackList.push({
          channelId: item.channelId,
          channelName: item.channelName,
          select: !!black_item,
          expirationDate: black_item ? black_item.expirationDate : "",
        });
        this.whiteList = whiteList;
        this.blackList = blackList;
      }
    },
    async getSeatGroupList() {
      let res = await this.$http.get(
        `/parking/seat/group/${this.parkingLotId}/all`
      );
      this.seatGroupList = res.data;
    },
    async getPriceList() {
      let res = await this.$http.get(`/price/simple/${this.parkingLotId}/list`);
      this.priceList = res.data;
    },
    reset() {
      this.leaseId = "";
      this.type = "无";
      this.seatGroupList = [];
      this.default_whiteList = [];
      this.default_blackList = [];
      this.blackList = [];
      this.blackList = [];
      this.leaseTimes = [];
      this.$set(this, "actionForm", {
        plateNum: "",
        subjectName: "",
        contacts: "",
        contactPhone: "",
        priceId: "",
        remarks: "",
        alwaysOpen: 0,
        leaseType: "",
        seatGroupId: "",
      });
      this.$refs["actionForm"].resetFields();
    },
    removeLeaseTime(index) {
      this.leaseTimes.splice(index, 1);
    },
    createLeaseTime() {
      if (this.leaseTimes.length === 0) {
        this.leaseTimes.push([undefined, undefined]);
      } else {
        if (
          this.leaseTimes[this.leaseTimes.length - 1][0] &&
          this.leaseTimes[this.leaseTimes.length - 1][1]
        ) {
          this.leaseTimes.push([undefined, undefined]);
        } else {
          this.$message.warning("请先选择上一个时间范围");
        }
      }
    },
    confirmBtn() {
      this.$refs["actionForm"].validate(async (valid) => {
        if (valid) {
          try {
            let url = "/price/lease/add";
            let params = {
              ...this.actionForm,
              parkingLotId: this.$route.params.id,
            };
            if (this.type === "无") {
              params.leaseType = null;
              params.seatGroupId = null;
            } else if (this.type === "免费") {
              if (
                this.leaseTimes.length &&
                (!this.leaseTimes[this.leaseTimes.length - 1][0] ||
                  !this.leaseTimes[this.leaseTimes.length - 1][1])
              ) {
                this.$message.warning("时间范围不能为空");
                return;
              }

              params.leaseTimes = this.leaseTimes.map((item) => {
                return {
                  startTime: item[0],
                  endTime: item[1],
                };
              });
              params.leaseType = 3;
            } else if (this.type === "收费") {
              params.seatGroupId = null;
            }

            const whitelist = [];
            const blacklist = [];

            for (let item of this.whiteList) {
              if (item.select) {
                if (item.expirationDate) {
                  whitelist.push({
                    channelId: item.channelId,
                    expirationDate: this.$moment(item.expirationDate),
                  });
                } else {
                  this.$message.warning("请选择白名单已勾选的到期时间");
                  return;
                }
              }
            }
            for (let item of this.blackList) {
              if (item.select) {
                if (item.expirationDate) {
                  blacklist.push({
                    channelId: item.channelId,
                    expirationDate: this.$moment(item.expirationDate),
                  });
                } else {
                  this.$message.warning("请选择黑名单已勾选的到期时间");
                  return;
                }
              }
            }
            params.whitelist = whitelist;
            params.blacklist = blacklist;

            if (this.leaseId) {
              url = "/price/lease/edit";
              params.leaseId = this.leaseId;
            }

            this.loading = true;
            let res = await this.$http.post(url, params);
            if (res.code === 0) {
              this.$message.success("操作成功");
              this.$emit("refreshList");
              this.show = false;
            } else {
              this.$message.error(res.msg);
            }
            this.loading = false;
          } catch (err) {
            this.$message.error(err);
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
#actionDialog {
  .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);

        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;

          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }

        .el-dialog__headerbtn {
          position: initial;

          i {
            color: black;
          }
        }
      }

      .el-dialog__body {
        padding: 15px;

        .el-select,
        .el-date-editor,
        .createTime {
          width: 100%;
        }

        .leaseTime {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .remove-leaseTime {
            white-space: nowrap;
            margin-left: 10px;
            cursor: pointer;
            color: $main-color;
          }
        }

        .createTime {
          margin-top: 10px;
        }

        .black-white-list {
          li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            .el-date-editor {
              width: 170px;

              .el-input__inner {
                padding-right: 10px;
              }
            }
          }
        }
      }

      .el-dialog__footer {
        text-align: center;

        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
